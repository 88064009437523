import Api from "../../../store/Services/Api";
import {store} from "../../../store/index";
class DashboardService{
    getUpperCardCountDatas(){
        const url = 'api/dashboard/upppercards'
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
}
export default new DashboardService;